/* Accueil.css */
.accueil {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .accueil-label{
    color: white;
    position: relative;
    text-align: center;
    font-size: 35px;
  }

  .background-image {
    background-image: url('../../Assets/event.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* animation: scrollBackground 5s linear infinite; */

  }

  
  @keyframes scrollBackground {
    0% {
      transform: scale(1);
    }
    50%{
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 65%;
    justify-content: space-between;
    height: 24%;
  }
  
  .accueil button{
    background-color: green;
    border: none;
    padding: 15px 50px;
    color: white;
    font-weight: bold;
    font-size: 25px;
    border-radius: 5px;
    
  }
  .accueil button:hover{
    color: rgb(229, 235, 229);
    background-color: transparent;
    cursor: pointer;
    border: solid 1px green;

  }
  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    width: 20%;
    height: 150px;
    justify-content: center;
    background-color: rgba(174, 180, 174, 0.6);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .5);
    padding: 15px;
    border-radius: 10px;

  }
  
  .countdown-value {
    font-size: 46px;
    font-weight: bold;
    color: white;
    
  }
  
  .countdown-label {
    font-size: 22px;
    color: white;
    margin-top: 5px;
  }


  .newlester {
    background-image: url('../../Assets/sponsor.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
   .about{
    width: 100%;
    background-color: #f0f0f0  !important;
  }
  .about img{
    width: 40%;
    display: inline-block;
    height: 400px;
  }
  
  .about-container {
    display: flex;
    width: 90%;
    margin: auto;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;

  }
  
  .about-item {
    display: flex;
    font-size: 21px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  .about-item p,   .about-item img{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}
.about-item p:hover,   .about-item img:hover{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: .5s;
}
.about-item p{
  position: relative;
  font-size: 18px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding: 5%;
  margin: 30px 0px;
  width: 40%;
}
.about-item img{
  border-radius: 20px 20px 20px 0px;
} 


/*Media Queries*/
@media (max-width: 768px) {
  .accueil-label {
    font-size: 22px;
  }
  .about img{
    width: 100%;
    height: 100%;
  }
  .about-item{
    padding: 5%;
    margin: 0px;
    width: 100%;
  }
  .about-item p{
    font-size: 15px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .accueil-label {
    font-size: 18px;

  }
  .about img{
    width: 100%;
    height: 100%;
  }
  .about h1{
    font-size: 25px;
  }
  .about-item{
    padding: 5%;
    margin: 0px;
    width: 100%;
  }
  .about-item p{
    font-size: 15px;
    width: 100%;
  }
  .countdown{
    width: 100%;
  }
  .countdown-label{
    font-size: 16px;
  }
}