.inscription-container{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    padding: 10px;
    margin: 9px auto;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inscription-form{
    width: 90%;
}
.form-item{
    width: 100%;
}
.form-item *{
    margin: 5px;
    font-size: 18px;
    
}

.buton{
    background-color: green;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 22px;
    border-radius: 5px;
    padding: 10px 40px;
    cursor: pointer;
    position: relative;
    right: -35%;
    
}
.buton:hover{
    color: black;
    border: solid 1px green;
    background-color: transparent;

}

/* Media Queries */
@media (max-width: 768px) {
    .inscription-container {
      width: 90% !important;
    }
  }
  
  @media (max-width: 576px) {
    .inscription-container {
      width: 100% !important;
    }
  }