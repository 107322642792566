.container{
    padding: 10px;
    margin: 9px auto 2% auto;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
table td th {
    padding: 10px;
}
.inscription-form{
    width: 90%;
}
.form-item{
    width: 100%;
}
.form-item *{
    margin: 5px;
    font-size: 18px;
    
}

.buton{
    background-color: green;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 22px;
    border-radius: 5px;
    padding: 10px 40px;
    cursor: pointer;
    position: relative;
    right: -35%;
    
}
.buton:hover{
    color: black;
    border: solid 1px green;
    background-color: transparent;


}